import React, { Component } from "react"
import { graphql, Link } from "gatsby"
import YouTube from "react-youtube"
import Layout from "../components/layout"
import SocialShare from "../components/social-share-links"
// import { MetaData } from "../components/meta"
//import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

class Video extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }
  render() {
    const video = this.props.data.youtubeVideo
    const vejaMais = this.props.data.allYoutubeVideo.edges
    return (
      <Layout>
             


        <div className="row mt-3">
          <div className="col-md-8 mx-auto">
            <h2>{video.title}</h2>

            <section className="mx-auto col-12 mt-3">
              <YouTube
                videoId={video.videoId} // defaults -> null
                id={video.videoId} // defaults -> null
                className={"embed-responsive-item"} // defaults -> null
                containerClassName={"embed-responsive embed-responsive-16by9"} // defaults -> ''
                opts={{
                  playerVars: {
                    autoplay: 1,
                    controls: 1,
                    modestbranding: 1,
                    rel: 0,
                    showinfo: 0,
                  },
                }}
              />
            </section>

            <section>
              <p>{video.description}</p>
            </section>
            <div className="d-flex align-items-baseline justify-content-end">
              <h6>Compartilhe: |</h6>
              <SocialShare
                href={this.props.location.href}
                title={video.title}
              />
            </div>

            <div className="row bg-light p-3 mt-3 rounded-lg">
              <div className="col-3">
                <h2>
                  <strong>
                    <small className="text-danger">
                      <i>
                        {/* <FontAwesomeIcon icon={["fas", "plus"]} /> */}
                      </i>
                    </small>{" "}
                    vídeos
                  </strong>
                </h2>
              </div>

              <div className="row mt-3">
                {vejaMais.map(({ node }) => (
                  <div key={node.videoId} className="col-4">
                    <h6 className="pt-1 tag-title">{node.data_simplificada}</h6>
                    <Link to={`/videos/${node.slug}`}>
                      <img
                        src={node.thumbnails.high.url}
                        alt={node.title}
                        className="img-fluid"
                      />

                      <p className="mt-2">
                        <strong>{node.title}</strong>
                      </p>
                    </Link>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export const postQuery = graphql`
  query($videoId: String!) {
    youtubeVideo(videoId: { eq: $videoId }) {
      ...YoutubeVideoFields
    }
    allYoutubeVideo(sort: { order: DESC, fields: publishedAt }, limit: 3) {
      edges {
        node {
          ...YoutubeVideoFields
        }
      }
    }
  }
`

export default Video
